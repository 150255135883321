@font-face {
    font-family: 'DSDigital';
    font-style: normal;
    font-weight: 400;
    src: url('theme/fonts/DS-Digital-Bold.ttf') format('truetype')
    /* TODO: get the DS-Digital woff,svg,eot files to ensure browser compatibility */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: scroll;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wizard-btn-back {
    height: 55px;
    color: #fff;
    font-family: 'Source Sans Pro', serif;
    font-size: 18px;
    line-height: 22px;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    width: 30%;
    background-color: #7C7C7C;
}

.wizard-btn-continue {
    height: 55px;
    background-color: #4D4F53;
    color: #fff;
    font-family: 'Source Sans Pro', serif;
    font-size: 18px;
    line-height: 22px;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0;
}

.wizard-btn-continue-fw {
    height: 55px;
    background-color: #4D4F53;
    color: #fff;
    font-family: 'Source Sans Pro', serif;
    font-size: 18px;
    line-height: 22px;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width:310px;
    margin: 0 auto;

}

.wizard-btn-submit {
    height: 55px;
    width: 100%;
    background-color: #4D4F53;
    color: #fff;
    font-family: 'Source Sans Pro', serif;
    font-size: 18px;
    line-height: 22px;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-danger {
    color: #721c24;
    background-color: #f5c6cb;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
}
